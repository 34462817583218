import 'alpinejs'
import './Js/schoollistview.js'
import './Scss/schoollist.scss';


import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
/* This code is needed to properly load the images in the Leaflet CSS */



var xhttp = new XMLHttpRequest();
xhttp.onreadystatechange = function() {

    if (this.readyState === 4 && this.status === 200) {
        // Typical action to be performed when the document is ready:
        let schools = JSON.parse ( xhttp.responseText )


        console.log(window.location.protocol + "//" +   window.location.hostname);
        initMap(schools)

        function initMap (schools) {

            let baseurl = window.location.protocol + "//" +   window.location.hostname;

            var greenIcon = L.icon({
                iconUrl: baseurl + '/typo3conf/ext/nw_mentor/Resources/Public/MapIcons/pointerGreen.gif',
                shadowUrl: baseurl + '/typo3conf/ext/nw_mentor/Resources/Public/MapIcons/pointerGreen.gif',

                iconSize:     [26, 26], // size of the icon
                shadowSize:   [0, 0], // size of the shadow
                iconAnchor:   [13, 0], // point of the icon which will correspond to marker's location
                shadowAnchor: [0, 0],  // the same for the shadow
                popupAnchor:  [0, 0] // point from which the popup should open relative to the iconAnchor
            });


            var yellowIcon = L.icon({
                iconUrl: baseurl + '/typo3conf/ext/nw_mentor/Resources/Public/MapIcons/pointerYellow.gif',
                shadowUrl: baseurl + '/typo3conf/ext/nw_mentor/Resources/Public/MapIcons/pointerYellow.gif',

                iconSize:     [26, 26], // size of the icon
                shadowSize:   [0, 0], // size of the shadow
                iconAnchor:   [13, 0], // point of the icon which will correspond to marker's location
                shadowAnchor: [0, 0],  // the same for the shadow
                popupAnchor:  [0, 0] // point from which the popup should open relative to the iconAnchor
            });


            var blueIcon = L.icon({
                iconUrl: baseurl + '/typo3conf/ext/nw_mentor/Resources/Public/MapIcons/pointerBlue.gif',
                shadowUrl: baseurl + '/typo3conf/ext/nw_mentor/Resources/Public/MapIcons/pointerBlue.gif',

                iconSize:     [26, 26], // size of the icon
                shadowSize:   [0, 0], // size of the shadow
                iconAnchor:   [13, 0], // point of the icon which will correspond to marker's location
                shadowAnchor: [0, 0],  // the same for the shadow
                popupAnchor:  [0, 0] // point from which the popup should open relative to the iconAnchor
            });


            var redIcon = L.icon({
                iconUrl: baseurl + '/typo3conf/ext/nw_mentor/Resources/Public/MapIcons/pointerRed.gif',
                shadowUrl: baseurl + '/typo3conf/ext/nw_mentor/Resources/Public/MapIcons/pointerRed.gif',
                iconSize:     [26, 26], // size of the icon
                shadowSize:   [0, 0], // size of the shadow
                iconAnchor:   [13, 0], // point of the icon which will correspond to marker's location
                shadowAnchor: [0, 0],  // the same for the shadow
                popupAnchor:  [0, 0] // point from which the popup should open relative to the iconAnchor
            });






            const map = L.map('map');
            const defaultCenter = [53.56, 9.96];
            const defaultZoom = 11;
            const basemap = L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
                attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
                id: 'mapbox/streets-v11',
                tileSize: 512,
                zoomOffset: -1,
                accessToken: 'pk.eyJ1IjoiYmVyemVyazMwMDAiLCJhIjoiY2t3ajNjeDkzMDVuajJ2cGRyemJjbG5zayJ9.3zVWT5I5XOnIJ5Mkj1VqjQ'
            });


            for (let i = 0; i < schools.length; i++) {
                 let link = document.getElementById('school-' + schools[i].id  ).getAttribute('href');

                let mapicon;

                switch (schools[i].typeOfSchool) {
                    case 'Grundschule':

                        mapicon = greenIcon
                         break;
                    case 'Grund\/Stadtteil':
                        mapicon = redIcon
                        break;

                    case 'Gymnasium':
                        mapicon = yellowIcon
                        break;
                    default:
                        mapicon = blueIcon
                        break;
                }





                     const marker = L.marker([schools[i].latitude, schools[i].longitude] , {icon: mapicon}).bindPopup('<b><a href="' + link +  '"> ' + schools[i].name +'</a> </b><br>'+ schools[i].street +', '+ schools[i].zip +' '+ schools[i].city)  ;
                     marker.addTo(map);
            }

//map.setView([53.300, 10.09], 13)
            map.setView(defaultCenter, defaultZoom);

            basemap.addTo(map);
            marker.addTo(map);

        }




    }
};
let baseurl = window.location.protocol + "//" +   window.location.hostname;

let callbackUrl = document.getElementById("map").getAttribute("date-callbackurl") + '.json';


xhttp.open("GET",  baseurl+ '/'  + callbackUrl, true);
xhttp.send();


/*     Map Schule singelview    */


var greenIcon = L.icon({
    iconUrl: baseurl + '/typo3conf/ext/nw_mentor/Resources/Public/MapIcons/pointerGreen.gif',
    shadowUrl: baseurl + '/typo3conf/ext/nw_mentor/Resources/Public/MapIcons/pointerGreen.gif',

    iconSize:     [26, 26], // size of the icon
    shadowSize:   [0, 0], // size of the shadow
    iconAnchor:   [13, 0], // point of the icon which will correspond to marker's location
    shadowAnchor: [0, 0],  // the same for the shadow
    popupAnchor:  [0, 0] // point from which the popup should open relative to the iconAnchor
});


var yellowIcon = L.icon({
    iconUrl: baseurl + '/typo3conf/ext/nw_mentor/Resources/Public/MapIcons/pointerYellow.gif',
    shadowUrl: baseurl + '/typo3conf/ext/nw_mentor/Resources/Public/MapIcons/pointerYellow.gif',

    iconSize:     [26, 26], // size of the icon
    shadowSize:   [0, 0], // size of the shadow
    iconAnchor:   [13, 0], // point of the icon which will correspond to marker's location
    shadowAnchor: [0, 0],  // the same for the shadow
    popupAnchor:  [0, 0] // point from which the popup should open relative to the iconAnchor
});


var blueIcon = L.icon({
    iconUrl: baseurl + '/typo3conf/ext/nw_mentor/Resources/Public/MapIcons/pointerBlue.gif',
    shadowUrl: baseurl + '/typo3conf/ext/nw_mentor/Resources/Public/MapIcons/pointerBlue.gif',

    iconSize:     [26, 26], // size of the icon
    shadowSize:   [0, 0], // size of the shadow
    iconAnchor:   [13, 0], // point of the icon which will correspond to marker's location
    shadowAnchor: [0, 0],  // the same for the shadow
    popupAnchor:  [0, 0] // point from which the popup should open relative to the iconAnchor
});


var redIcon = L.icon({
    iconUrl: baseurl + '/typo3conf/ext/nw_mentor/Resources/Public/MapIcons/pointerRed.gif',
    shadowUrl: baseurl + '/typo3conf/ext/nw_mentor/Resources/Public/MapIcons/pointerRed.gif',
    iconSize:     [26, 26], // size of the icon
    shadowSize:   [0, 0], // size of the shadow
    iconAnchor:   [13, 0], // point of the icon which will correspond to marker's location
    shadowAnchor: [0, 0],  // the same for the shadow
    popupAnchor:  [0, 0] // point from which the popup should open relative to the iconAnchor
});


const school = document.getElementById("mapdatas")





const map = L.map('mapshow');
const defaultCenter = [school.dataset.latitude, school.dataset.longitude];
const defaultZoom = 15;
const basemap = L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
    attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',

    id: 'mapbox/streets-v11',
    tileSize: 512,
    zoomOffset: -1,
    accessToken: 'pk.eyJ1IjoiYmVyemVyazMwMDAiLCJhIjoiY2t3ajNjeDkzMDVuajJ2cGRyemJjbG5zayJ9.3zVWT5I5XOnIJ5Mkj1VqjQ'
});

const marker = L.marker(defaultCenter , {icon: redIcon})  ;
marker.addTo(map);

map.setView(defaultCenter, defaultZoom);

basemap.addTo(map);














